<template>
	<b-modal id="activate-storage-location" title="Storage Location Activation" ok-title="Save" ref="modal"
		@hide="handleClose" @ok.prevent="handleOk" :cancel-disabled="disableConfirmButtons"
		:ok-disabled="disableConfirmButtons" :no-close-on-backdrop="true">
		<loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />

		<div class="confirmMessage">
			Are you sure you want to
			<b>{{ isActive === 'true' ? 'deactivate' : 'activate' }}</b>
			this storage location <b>"{{ name }}"</b>?
		</div>
	</b-modal>
</template>

<script>
// Util
import { StorageLocationUtil } from '@/utils/storageLocationUtil';

// Others
import config from '@/config/env-constants';
import EventBus from '@/shared/event-bus';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import _ from 'lodash';

export default {
	name: 'activate-storage-location',
	components: {
		Loading,
	},
	props: {
		allCompaniesObj: {
			type: Object,
			required: true,
		},
		allStorageLocationsObj: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			selStorageLocation: {},
			isActive: true,

			// Check for loader
			isLoading: false,
		};
	},
	computed: {
		adminAccount() {
			return config.adminAccount.TAWI_SUPPORT;
		},
		disableConfirmButtons() {
			return this.isLoading;
		},
		name() {
			return this.selStorageLocation.name;
		},
		isForActivation() {
			// NOTE: reversed for display purposes only
			return this.isActive === 'false';
		},
	},
	mounted() {
		EventBus.$on('onUpdateSelStorageLocation', (storageLocation) => {
			this.selStorageLocation = storageLocation;
			this.isActive = storageLocation.isActive;
		});
	},
	methods: {
		toggleStatus(isActive) {
			let status = 'true';
			if (isActive === 'true') {
				status = 'false';
			}
			return status;
		},
		resetStatus() {
			this.selStorageLocation.isActive = this.isActive;
			this.$store.commit('SET_CURR_STORAGE_LOCATION', this.selStorageLocation);
		},
		handleClose() {
			this.resetStatus();
		},

		async validateDeactivation() {
			if (await this.isLocPartOfActiveConnection()) {
				let warningMsg = `Cannot deactivate. Storage Location is included in an active connection.`;
				this.$toaster.warning(warningMsg);
				return false;
			}

			return true;
		},
		// checks if selected storage location is included in an active connection
		async isLocPartOfActiveConnection() {
			let hasExisting = false;

			let connectionsObj = await this.$store.dispatch(
				'getConnectionsByConnectedCompany',
				{
					companyId: this.selStorageLocation.companyId,
				}
			);

			_.forEach(connectionsObj, (con) => {
				// get list of storage locations in connection
				let conLocsArr = Object.values(con.storageLocations);
				conLocsArr.forEach((conLocation) => {
					if (
						conLocation.id === this.selStorageLocation.id &&
						conLocation.isIncluded === 'true'
					) {
						hasExisting = true;
					}
				});
			});

			return hasExisting;
		},

		validateActivation() {
			if (
				StorageLocationUtil.exceedMaximum(
					this.allCompaniesObj[this.selStorageLocation.companyId],
					this.allStorageLocationsObj
				)
			) {
				let warningMsg = `You have exceeded the allowed no of active storage location of your company. Please contact the administrator (${this.adminAccount}) for assistance.`;
				this.$toaster.warning(warningMsg);
				return false;
			}
			return true;
		},

		async handleOk(evt) {
			// Prevent modal from closing
			evt.preventDefault();

			if (
				(this.isActive === 'true' && (await this.validateDeactivation())) ||
				(this.isActive === 'false' && this.validateActivation())
			) {
				await this.handleSubmit();
			}
		},
		async handleSubmit() {
			// show loading indicator
			this.isLoading = true;

			// update the status
			this.selStorageLocation.isActive = this.toggleStatus(this.isActive);

			try {
				const result = await this.$store.dispatch(
					'updateStorageLocation',
					this.selStorageLocation
				);

				// hide loading indicator
				this.isLoading = false;
				this.$refs.modal.hide();

				if (result.isSuccess) {
					let successMsg = '';
					if (result.data.isActive === 'true') {
						successMsg = `Storage Location "${this.name}" is now activated!`;
					} else {
						successMsg = `Storage Location "${this.name}" is now deactivated!`;
					}

					this.$toaster.success(successMsg);
					EventBus.$emit('onCloseEditStorageLocation', result.data);
				} else {
					let errorMsg = '';
					if (this.toggleStatus(this.isActive) === 'true') {
						errorMsg = `There\'s a problem encountered during account activation of the storage location"${this.name}"`;
					} else {
						errorMsg = `There\'s a problem encountered during account deactivation of the storage location "${this.name}"`;
					}

					this.resetStatus();
					this.$toaster.error(errorMsg);
				}
			} catch (error) {
				let errorMsg = '';
				if (this.toggleStatus(this.isActive) === 'true') {
					errorMsg = `There\'s a problem encountered during activation of the storage location "${this.name}"`;
				} else {
					errorMsg = `There\'s a problem encountered during deactivation of the storage location "${this.name}"`;
				}

				this.resetStatus();
				this.$toaster.error(errorMsg);

				// hide loading indicator
				this.isLoading = false;
			}
		},
	},
	beforeDestroy() {
		EventBus.$off('onUpdateSelStorageLocation');
	},
};
</script>

<style scoped>

</style>
